.App {text-align: center;color: "red";}
.App-logo {height: 40vmin;pointer-events: none;}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {background-color: #282c34;min-height: 100vh;display: flex;flex-direction: column;align-items: center;justify-content: center;font-size: calc(10px + 2vmin);color: white;}
.App-link {color: #61dafb;}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.home_pageCard {width: auto;margin: auto;box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);margin-bottom: 70px;padding-bottom: 0;}
.mainLogo {max-width: 300px;max-height: 100px;}
.slick-initialized .slick-slide:focus,
.slick-initialized .slick-slide>div:focus {outline: none;}
div:focus {outline: none !important;}
.slick-slider .slick-dots li button:before {font-size: 16px;}
.slick-dots li.slick-active button:before {opacity: 0.75;color: #007bff;}
.reviewSlide {width: 100%;text-align: center;object-fit: cover;padding-right: 15px !important;padding: 10px 0;
  .card {padding: 12px;border-radius: 8px;border: none;
    -webkit-box-shadow: 0 0 12px -5px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0 0 12px -5px rgba(0, 0, 0, 0.75);
    box-shadow: 0 0 12px -5px rgba(0, 0, 0, 0.75);
    transition: all ease-in-out 300ms;
    &:hover {transform: scale(1.04);}
    .instragram_Icon {width: 30px;margin: 0 auto 10px;}
    .card-header {font-size: 14px;font-weight: 700;border-bottom: none;padding-bottom: 0;color: #868686;}
    .card-footer {border-top: none;}
  }
}
.card {display: flex;align-items: center;}
.reviewSlider {padding-top: 40px;padding-bottom: 30px !important;background: #f8f9fa !important;}
.reviewImage {width: 100%;height: 75%;}
.bottomCardOptions {padding-bottom: 30px;padding-top: 30px;width: 100%;}
.column {padding-bottom: 30px;padding-top: 30px;float: left;width: 33.33%;height: 50px;text-align: center;}
.row:after {content: "";display: table;clear: both;}
.slick-dots li button:before {content: "•" !important;}
.promoVideo {display: block;margin-left: auto;margin-right: auto;border-radius: 20px;text-align: center;max-width: 700px;width: auto;position: relative;
  .videoPlayBtn {position: absolute;top: 50%;left: 50%;-webkit-transform: translate(-50%, -50%);transform: translate(-50%, -50%);cursor: pointer;z-index: 1;
    img {width: 100px;}
  }
}
.photoFrame_video {
  video {width: 100%;border-radius: 20px;box-shadow: 1px 6px 10px 0 #0c0c0c26;}
}
.headerText {padding-top: 40px;padding-right: 5px;padding-bottom: 40px;color: #2d2d2d;padding-left: 5px;
font-size: larger;font-weight: bold;text-align: center;font-size: 30px;max-width: 700px;text-align: center;margin: 0 auto;}
.headerText2 {padding-top: 5px;padding-right: 5px;padding-bottom: 10px;padding-left: 5px;font-size: large;
font-weight: 600;text-align: center;color: #007bff;}
.headerText3 {padding-top: 30px;padding-right: 10px;padding-bottom: 33px;padding-left: 10px;font-size: large;text-align: center;}
.reviewStars {padding-top: 30px;text-align: center;}
.priceText {padding-top: 5px;padding-right: 5px;padding-bottom: 5px;padding-left: 5px;font-size: larger;color: #007bff;text-align: center;font-weight: 600;}
.reviewStars {text-align: center;
  p {margin-top: 18px;font-weight: 500;color: #242424;}
}
.loadingModal {background-color: lightgray;}
.home_pageCard.kak-pageCard body>#root>div>div {height: auto !important;}
.selectPhoto_pageCard {width: 100%;
  @media (max-width: 1500px) {
    max-width: 1500px;
  }
  margin: auto;
  padding: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.vcentre {display: table-cell;vertical-align: middle;}
.previewContainer {display: flex;align-items: center !important;justify-content: center !important;text-align: center !important;height: 100%;
  @media (min-width: 960px) {
    display: flex !important;
    flex-wrap: wrap;
  }
}
@media(max-width:499px) {
  .navbar-expand .navbar-nav .dropdown-menu {
    right: -30px !important;
    left: auto !important;
  }
}
@media(min-width:500px) {
  .navbar-expand .navbar-nav .dropdown-menu {right: 0 !important;left: auto !important;
  }
  #menuPopOver {padding: 0 15px 0 0px;}
}
div.scrollmenu {background-color: #f8f9fa !important;overflow-x: auto;min-height: 120px;white-space: nowrap;text-align: center;padding-bottom: 2px;overflow-y: hidden;box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);padding: 10px 0;}
.scrollmenuPreview {background-color: transparent;overflow: auto;white-space: nowrap;color: lightsteelblue;background-color: lightsteelblue;text-align: center;background-image: url("../public/assets/Photos/gallery\ Wall-B&W.jpeg");padding-top: 10px;padding-bottom: 60px;display: flex;flex-direction: column;height: 100%;position: relative;z-index: 0;
  &::after {content: "";position: absolute;top: 0;left: 0;width: 100%;height: 100%;background: #ffffff9c;z-index: -1;}
  h5 {font-size: 16px;text-transform: uppercase;color: #000 !important;}
}
.previewContainer>div {display: inline-block;text-align: center;text-decoration: none;justify-content: center;align-items: center;padding: 10px;}
.centerHorizontal {display: flex;justify-content: center;align-items: center;}
.previewImage {height: 253px !important;width: 253px !important;justify-content: center;align-items: center;text-align: center;display: flex;background-color: transparent;color: transparent;border-color: transparent;
  @media (min-width: 960px) {
    height: 33%;
    width: 33%;
  }
  animation: appear 2s ease 0s 1 normal none running;
}
.first {max-width: 272px;max-height: 272px;width: 100%;height: 100%;}
.second {position: absolute;left: 5.5%;top: 5.5%;display: block;max-width: 89.5%;margin-left: auto;margin-right: auto;}
.secondMount {position: absolute;left: 18%;top: 18%;display: block;max-width: 64%;margin-left: auto;margin-right: auto;}
.footer {position: fixed;left: 0;bottom: 0;width: 100%;color: white;text-align: center;}
.styleHolder {display: flex;justify-content: center;
  >div {padding: 0 5px;
    .card {padding: 5px;border: none;text-align: center;box-shadow: 2px 5px 7px -1px rgba(0, 0, 0, 0.2);background: #f1f1f1;text-align: center;
      .imageText {width: 100%;font-weight: 600;font-size: 14px;margin-top: 5px;color: #717171;}
    }
  }
}
.imageStyle {max-width: 60px;height: auto;}
.imageStyleDiv {background-color: transparent;overflow: auto;white-space: nowrap;text-align: center;font-size: 13px;text-transform: uppercase;}
.imagetext {margin-top: 2px;font-weight: 700;}
.lightblue {color: lightskyblue !important;background-color: lightskyblue !important;background: lightskyblue !important;}
.blue {color: blue !important;}
.red {color: red !important;}
.gray {color: gray !important;}
.previewImage {animation-name: example;animation-duration: 1.5s;animation-iteration-count: 1;
animation-timing-function: ease-in-out;}
@keyframes example {
  0% {
    left: -200px;
    top: 0px;
  }
  100% {
    left: 0px;
    top: 0px;
  }
}
.mainContainer {display: flex;justify-content: center;align-items: center;height: max-content;}
.formContainer {width: 90%;border-radius: 2%;border-color: black;height: max-content;}
div.formContainer div {margin: 2px !important;padding-top: 5px !important;padding-bottom: 10px !important;}
.inputItem {margin: 2px !important;padding: 10px !important;}
.exitIcon {color: red;}
.exitButton {background: transparent;border: none;}
h8 {color: red;}
.infoRow {width: 80%;display: inline-block;}
.right {float: right;position: relative;}
.left {float: left;}
.footerHolder {background: white;bottom: 0;position: fixed;text-align: center;max-width: 1500px;z-index: 99;}
.footer {position: fixed;left: 0;bottom: 0;width: 100%;color: white;background-color: white;padding: 10px;box-shadow: rgba(50, 50, 50, 0.2) 0px -2px 5px;font-size: larger;}
.footerButton {text-align: center;max-height: 100vh;width: 80%;height: 80%;max-width: 400px;height: 50px;border-radius: 10px;font-size: 20px;font-weight: 600;}
.footerButton:hover {box-shadow: 0 3px 12px rgba(0, 123, 255, 0.3);}
.card-header,
.card-footer {background: #fff;}
.dropdown-menu {right: 0;left: auto;}
.dropdown-menu[data-bs-popper] {top: 100%;left: -106px !important;margin-top: var(--bs-dropdown-spacer);}
.mainLogo {max-width: 220px;border: 0;}
.imageContainer {justify-content: center;display: flex;text-align: center;}
.page_header {background: #fff;padding: 10px 0;box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);position: relative;z-index: 99;}
.imageContainer {height: auto;width: 100%;}
img {max-width: 100%;}
input[type="file"] {visibility: hidden;opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.uploadButton {border-radius: 2%;border-color: #007bff;border-style: dashed;border-width: 2px;height: 250px !important;width: 250px !important;justify-content: center;align-items: center;text-align: center;box-shadow: 0 0 0 0 rgba(0, 123, 255, 1);transform: scale(1);}
.imageContainer {border-width: 2px;align-items: center;text-align: center;display: flex;}
.frame {border: 18px solid rgb(14, 13, 13);padding: 24px;height: 250px;width: 250px;margin-bottom: 10px;}
.uploadButtonMouseOver {border-radius: 50%;border-color: grey;border-style: dashed;border-width: 2px;height: 250px !important;width: 250px !important;justify-content: center;align-items: center;text-align: center;display: flex;box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);transform: scale(1);background-color: white;cursor: pointer;
  &:hover {
    .hover_plus_icon {opacity: 0;visibility: hidden;}
  }
}
div.uploadButtonMouseOver>div {height: 100%;width: 100%;padding: 10px 0;border-radius: 50%;}
.uploadIcon img {width: 46px;}
.outer {display: flex !important;flex-direction: column !important;padding: 0px !important;height: 100%;width: 100%;}
.file_upload_top {height: 100%;width: 100%;display: flex;position: relative;text-align: center;padding: 5px 0;flex-direction: column;justify-content: center;align-items: center;border-bottom: 1px solid #d4d4d4;border-radius: 50%;margin: 0 auto;}
.file_upload_buttom {height: 50%;width: 100%;padding-right: 0px;-webkit-display: flex;display: flex;flex-direction: column;align-items: center;justify-content: center;cursor: pointer;
  &:hover {
    .social_icons {
      ul {
        li {
          i {color: #27bdd8;}
        }
      }
    }
  }
  h4 {font-size: 14px;flex: 0 0 100%;word-break: break-all;color: #333;font-weight: 700;white-space: pre-wrap;}
}
.social_icons {
  ul {list-style: none;margin-bottom: 5px;padding-left: 0;
    li {font-size: 18px;
      i {color: #7d7d7d;font-size: 22px;
        -webkit-transition: all ease-in-out 300ms;
        -moz-transition: all ease-in-out 300ms;
        transition: all ease-in-out 300ms;
      }
    }
  }
}
.frame-head {display: flex;align-items: center;justify-content: center;flex-direction: column;}
.action-buttons {margin-top: 9px;position: absolute;left: 0;right: 0;bottom: 0;z-index: 111;}
.action-buttons button {border: none;padding: 12px;}
.uploadFromPcText {z-index: 3;font-size: 16px;font-weight: 700;color: #848685;margin: 10px 0 0;}
div.outer div:hover {background: #e6fbff;}
.socialIcon {margin-left: 5px;margin-right: 5px;}
.hover_plus_icon {position: absolute;top: 0;left: 0;width: 100%;height: 100%;font-size: 90px;background: #fff;z-index: 4;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  span {position: absolute;top: 50%;left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
.previewContainer .fram-main-box {display: inline-block;justify-content: start;margin: 0 10px 10px;overflow: auto !important;white-space: nowrap !important;width: 100%;max-width: 80%;}
.previewContainer .fram-main-box .frame {margin: 0 10px 10px;white-space: nowrap;display: inline-block;position: relative;padding: 20px;}
.action-buttons button i {display: block;}
.previewContainer .fram-main-box .file_upload_top {margin: 0;padding: 20px 0 0;}
.upload-sec {position: relative;}
.frame img {object-fit: cover;width: 100% !important;height: 100% !important;}
.input-range {text-align: center;padding: 10px;}
.custom-button {min-width: 209px;height: 52px;font-size: 18px;border-radius: 8px;color: rgb(255, 255, 255);background: rgb(235, 35, 113);border: 0px;display: flex;
  -weblit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 75%;
  margin: 0 auto;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
  outline: 0px;
  transition: all 0.2s ease 0s;
}
.crop-range {width: 80%;}
.increment {background-color: #ededed !important;}
.decrement {background-color: #ededed !important;}
button.rounded-circle {color: #000000de !important;}
.decrement {position: relative;right: 13px;}
.increment {position: relative;left: 13px;}
button.rounded-circle:hover {box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);}
.drag {text-align: center;color: rgb(149, 130, 137);}
.btn.btn-light {color: #8c8c8c;padding: 5px;}
.adjust {width: 80%;text-align: center;display: inline-block;}
button.btn.btn-primary.cancel {background: rgb(235, 35, 113);border: none;}
button.btn.btn-warning.done:hover {background: rgb(235, 35, 113);color: #fff;}
button.btn.btn-warning.done {border: none;background: gray;color: #fff;}
.confirm-delete {color: gray;}
.frame.common-fram.Black {padding: 0 !important;}
.frame.common-fram.Black-Mount {padding: 35px;}
.frame.common-fram.White-Mount {padding: 35px;background: #fff;}
.frame.common-fram.White {padding: 0;}
.card.active {border: 1px dotted #333 !important;}
.card {border: 1px solid transparent !important;}
.mat-color {border-radius: 50%;border: 1px solid #000;width: 35px !important;height: 35px;display: block;}
.confirm-delete.modal-body {display: flex;}
.mat-color-mat.modal-body {display: flex;justify-content: space-around;align-items: center;}
.mat-color.one {border-color: gray;}
.mat-color.two {border: 2px solid rgba(0, 0, 0, 0.1);background-color: rgb(249, 236, 221);}
.mat-color.three {border: 2px solid rgba(0, 0, 0, 0.1);background-color: rgb(245, 227, 226);}
.mat-color.four {border: 2px solid rgba(0, 0, 0, 0.1);background-color: rgb(211, 232, 254);}
.mat-color.five {border: 2px solid rgba(0, 0, 0, 0.1);background-color: rgb(197, 218, 217);}
.mat-color.six {border: 2px solid rgba(0, 0, 0, 0.1);background-color: rgb(157, 137, 116);}
.mat-color.seven {border: 2px solid rgba(0, 0, 0, 0.1);background-color: rgb(213, 116, 127);}
.mat-color.eight {border: 2px solid rgba(0, 0, 0, 0.1);background-color: rgb(91, 120, 158);}
.mat-color.nine {border: 2px solid rgba(0, 0, 0, 0.1);background-color: rgb(125, 163, 161);}
.frame.common-fram.Black-Mount.one {background-color: #fff;}
.frame.common-fram.White.one {background-color: gray;}
.frame.common-fram.White.two {background-color: rgb(249, 236, 221);}
.frame.common-fram.White.three {background-color: rgb(245, 227, 226);}
.frame.common-fram.White.four {background-color: rgb(211, 232, 254);}
.frame.common-fram.White.five {background-color: rgb(197, 218, 217);}
.frame.common-fram.White.six {background-color: rgb(157, 137, 116);}
.frame.common-fram.White.seven {background-color: rgb(213, 116, 127);}
.frame.common-fram.White.eight {background-color: rgb(91, 120, 158);}
.frame.common-fram.White.nine {background-color: rgb(125, 163, 161);}
.frame.common-fram.Black-Mount.two {background-color: rgb(249, 236, 221);}
.frame.common-fram.Black-Mount.three {background-color: rgb(245, 227, 226);}
.frame.common-fram.Black-Mount.four {background-color: rgb(211, 232, 254);}
.frame.common-fram.Black-Mount.five {background-color: rgb(197, 218, 217);}
.frame.common-fram.Black-Mount.six {background-color: rgb(157, 137, 116);}
.frame.common-fram.Black-Mount.seven {background-color: rgb(213, 116, 127);}
.frame.common-fram.Black-Mount.eight {background-color: rgb(91, 120, 158);}
.frame.common-fram.Black-Mount.nine {background-color: rgb(125, 163, 161);}
.mat-color.one.active {border: 2px solid rgb(235, 35, 113);}
.mat-color.two.active {border: 2px solid rgb(235, 35, 113);}
.mat-color.three.active {border: 2px solid rgb(235, 35, 113);}
.mat-color.four.active {border: 2px solid rgb(235, 35, 113);}
.mat-color.five.active {border: 2px solid rgb(235, 35, 113);}
.mat-color.six.active {border: 2px solid rgb(235, 35, 113);}
.mat-color.seven.active {border: 2px solid rgb(235, 35, 113);}
.mat-color.eight.active {border: 2px solid rgb(235, 35, 113);}
.mat-color.nine.active {border: 2px solid rgb(235, 35, 113);}
.crop-mat {display: flex;justify-content: space-around;padding: 10px;}
.mat-button {border: none;padding: 12px;display: flex;justify-content: center;align-content: center;width: 100%;flex-direction: column;background: #e9e9ed;max-width: 60px;margin: 0 auto;margin-top: -20px;}
.frame.common-fram.Black,
.cropper-drag-box.cropper-crop.cropper-modal {border-image: url("../public/assets/Photos/Mount-PNG.png") 22 round;}
.frame.common-fram.Black-Mount {border-image: url("../public/assets/Photos/Mountedd.png") 22 round;background-color: #fff !important;border-radius: 20px;}
.frame.common-fram.White-Mount {border-image: url("../public/assets/Photos/Mount.jpg") 25 round;border-radius: 20px;}
.frame.common-fram.White {border-image: url("../public/assets/Photos/mounted.png") 25 round;border-radius: 20px;}
.uploadButtonMouseOver.position-fix {position: fixed;right: 0;top: 60%;width: 150px !important;height: 150px !important;}
.navbar-nav a.back-button.btn.btn-light {padding-left: 15px;}
@media (max-width: 767px) {
  .hover_plus_icon {display: none;}
  .uploadFromPcText {font-size: 10px;}
  .uploadIcon img {width: 25px;}
  .uploadButtonMouseOver {height: 100px !important;width: 100px !important;}
  .uploadButtonMouseOver.position-fix {top: 76%;width: 100px !important;height: 100px !important;}
}
#crop-modal .common-fram:hover {position: relative;width: 250px !important;height: 250px !important;}
.Black-Mount {border: 30px solid transparent;padding: 35px;border-image: url("../public/assets/Photos/Mountedd.png") 24 round;}
.White-Mount {border: 30px solid transparent;padding: 35px;border-image: url("../public/assets/Photos/Mount.jpg") 25 round;}
.White {border: 30px solid transparent;border-image: url("../public/assets/Photos/mounted.png") 25 round;}
.Black {border: 30px solid transparent;border-image: url("../public/assets/Photos/Mount-PNG.png") 22 round;}
.Canvas {border: 30px solid transparent;}
.styleHolder>div .card {padding: 6px;border: 1px solid rgb(0 0 0 / 15%) !important;background-color: #f9f9f9;box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);transition: all 0.3s ease-in-out;min-width: 90px;}
.styleHolder>div .card:hover {background-color: #daf9ff;}
.scrollmenuPreview {background-size: cover;padding: 35px 0;height: auto;background-image: none;background-color: #e7e7e7;}
.scrollmenu {padding: 0px 0 15px !important;overflow-x: auto !important;min-height: auto !important;}
.scrollmenuPreview h5 {font-size: 21px;}
.hover_plus_icon span {color: #27bdd8;font-weight: lighter;border-radius: 50px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.hover_plus_icon {display: flex;align-items: center;justify-content: center;font-size: 50px;}
.vcentre .uploadButtonMouseOver {-webkit-animation: beat 8s ease infinite;animation: beat 3s ease infinite;border: none;bottom: 0;box-shadow: 0 7px 10px 0 rgba(0,0,0,.15);height: 100px!important;left: 50%;position: fixed;right: auto;top: 50%;-webkit-transform: translate(-50%,-50%);transform: translate(-50%,-50%);transition: all .3s ease-in-out;width: 100px!important;z-index: 999;}
@keyframes beat {
  0% {
    border-color: #27bdd8;
    -webkit-transform: scale(1);
    transform: scale(1) translate(-50%,-50%);
    transform-origin: 0% 0%;
  }
  50% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15) translate(-50%,-50%);
    transform-origin: 0% 0%;
    border-color: #888;
  }
  100% {
    border-color: #888;
    -webkit-transform: scale(1);
    transform: scale(1) translate(-50%,-50%);
    transform-origin: 0% 0%;
  }
}
.vcentre .uploadButtonMouseOver span svg {animation: color-change 8s infinite alternate;font-weight: 400;font-size: 80px;display: flex;align-items: center;justify-content: center;}
@keyframes color-change {
  0% {
    fill: #27bdd8;
  }
  50% {
    fill: #888;
  }
  100% {
    fill: #27bdd8;
  }
}
.uploadButtonMouseOver.position-fix {animation: none;width: 80px !important;height: 80px !important;}
.vcentre .uploadButtonMouseOver.position-fix span svg {animation: none !important;fill: #27bdd8 !important;
font-size: 53px;}
.uploadButtonMouseOver.position-fix .uploadFromPcText {font-size: 9px;}
.uploadFromPcText {width: 100%;max-width: 70px;word-wrap: break-word;white-space: break-spaces;text-transform: uppercase;font-size: 11px;text-align: center;}
.uploadIcon img {width: auto;height: 30px;}
.styleHolder>div {padding: 0;margin: 0 5px;}
.styleHolder {justify-content: inherit;margin: 0 auto;display: inline-flex;}
.navbar .btn-light i {color: #848685 !important;}
.navbar .btn-light {background-color: transparent !important;box-shadow: 0 0 !important;border: 0 !important;}
.vcentre .uploadButtonMouseOver.position-fix {position: fixed;transform: none;right: 20px;bottom: 90px;top: auto;left: auto;}
.vcentre .uploadButtonMouseOver:hover {bottom: 42%;box-shadow: 0 0 0 0 rgb(0 0 0 / 15%);}
.footer .footerButton,
.crop-pop-up .custom-button,
.crop-pop-up .custom-button:focus {background-color: #27bdd8 !important;border-color: #27bdd8 !important;width: 100%;max-width: 150px;display: flex;align-items: center;justify-content: center;margin: 0 auto; white-space: nowrap;gap: 5px;}
.scrollmenuPreview::after {display: none;}
.vcentre .uploadButtonMouseOver.position-fix:hover {top: auto;bottom: 85px;}
.previewContainer .fram-main-box {max-width: 100%;}
.previewContainer .vcentre {padding: 0;}
.action-buttons button {margin: 0 3px;border-radius: 5px;line-height: normal;padding: 8px;font-size: 16px;transition: all 0.3s ease-in-out;}
.action-buttons {bottom: 12px;}
.action-buttons button:hover {background-color: #27bdd8;color: #fff;}
.crop-pop-up .input-range .rounded-circle {display: inline-flex;align-items: center;justify-content: center;line-height: normal;padding: 0;min-height: 40px;width: 40px;background-color: transparent !important;border: 2px solid #d9d9d9 !important;color: #959595 !important;}
.crop-pop-up input[type="range"] {overflow: hidden;
  -webkit-appearance: none;background-color: #f2f2f2;width: 100%;max-width: 120px;border-radius: 20px;}
.crop-pop-up input[type="range"]::-webkit-slider-runnable-track {height: 10px;-webkit-appearance: none;color: #27bdd8;margin-top: -1px;}
.crop-pop-up input[type="range"]::-webkit-slider-thumb {width: 10px;-webkit-appearance: none;height: 10px;cursor: ew-resize;background: #434343;box-shadow: -80px 0 0 80px #27bdd8;}
.crop-pop-up input[type="range"]::-moz-range-progress {background-color: #27bdd8;}
.crop-pop-up input[type="range"]::-moz-range-track {background-color: #f2f2f2;}
.crop-pop-up input[type="range"]::-ms-fill-lower {background-color: #27bdd8;}
.crop-pop-up input[type="range"]::-ms-fill-upper {background-color: #f2f2f2;}
::-webkit-scrollbar {height: 8px;width: 8px;border-radius: 10px;}
::-webkit-scrollbar-track {background: #f1f1f1;border-radius: 10px;}
::-webkit-scrollbar-thumb {background: #27bdd8;border-radius: 10px;}
.crop-pop-up .input-range .rounded-circle svg {height: 10px;width: 10px;}
.crop-pop-up .input-range {display: flex;justify-content: center;align-items: center;}
.styleHolder>div .card.active {background-color: #daf9ff;}
.fram-main-box {position: relative;}
.frame.common-fram.Black.black-frame::after {content: "";position: absolute;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.3);transition: all 0.3s ease 0;z-index: 10;cursor: pointer;opacity: 0;}
.frame.common-fram.Black.black-frame:hover::after {opacity: 1;}
.frame.common-fram.White-Mount.black-frame::after {content: "";position: absolute;top: 0;left: -2px;width: 101%;height: 100%;background-color: rgba(0, 0, 0, 0.3);transition: all 0.3s ease 0;z-index: 10;cursor: pointer;opacity: 0;}
.frame.common-fram.White-Mount.black-frame:hover::after {opacity: 1;}
.frame.common-fram.White.black-frame::after {content: "";position: absolute;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.3);transition: all 0.3s ease 0;z-index: 10;cursor: pointer;opacity: 0;}
.frame.common-fram.White.black-frame:hover::after {opacity: 1;}
.frame.common-fram.Black-Mount.black-frame::after {content: "";position: absolute;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.3);transition: all 0.3s ease 0;z-index: 10;cursor: pointer;opacity: 0;}
.frame.common-fram.Black-Mount.black-frame:hover::after {opacity: 1;}
.styleHolder>div .card .imageStyle {max-width: 40px;}
.styleHolder>div .card .imageText {font-size: 12px;}
.mainLogo {max-width: 170px;}
.pageCard {background-color: #e7e7e7;height: 100vh;}
.fram-main-box {margin-bottom: 40px !important;}
.frame.common-fram.Canvas.black-frame {padding: 0 !important;margin: 20px 15px 20px;}
.Canvas:before {content: "";background-image: url(../public/assets/Photos/shallow-edge.png);height: 343px;width: 343px;display: block;background-repeat: no-repeat;background-size: contain;top: -21px;left: -1px;background-position: center;position: absolute;z-index: 2;}
.frame.common-fram {position: relative;}
.frame.common-fram img {position: relative;z-index: 2;width: 100% !important;height: 100% !important;}
.crop-pop-up {position: relative;}
.cropper-container {position: relative;z-index: 11;}
.crop-pop-up .Canvas {position: relative;display: flex;justify-content: center;align-items: center;}
.crop-pop-up .Canvas:before {left: 50%;top: 39%;transform: translate(-50%, -50%);width: 323px;}
#crop-modal {max-width: 414px;margin-left: auto;margin-right: auto;}
.colum-row {width: 100%;overflow: hidden;display: flex;justify-content: center;background: #888888;}
.colm {padding: 20px 20px;font-weight: 400;color: #fff;}
.crop-pop-up .White-Mount,.crop-pop-up .Black-Mount,.crop-pop-up .Black,.crop-pop-up .White {height: 380px !important;}
.crop-pop-up .White-Mount .cropper-container,.crop-pop-up .Black-Mount .cropper-container,.crop-pop-up .White-Mount .crop-pop-img-head,.crop-pop-up .Black-Mount .crop-pop-img-head {height: 250px !important;}
.frame.Canvas {position: relative;}
.frame.Canvas img {position: absolute;left: 0px;top: -20px;height: 340px !important;min-width: 340px !important;}
.crop-pop-up .Canvas .crop-pop-img-head img {opacity: 1;}
.home_pageCard .priceText {padding: 25px 0px 25px;color: #242424;}
.home_pageCard .headerText2 {color: #059fbb;}
.home_pageCard .reviewStars {padding: 0px 0px 0px;color: #242424;}
.home_pageCard .reviewStars svg {fill: #27bdd8;}
.reviewcon {max-width: 545px !important;border-top: 1px solid rgb(0 0 0 / 15%);border-bottom: 1px solid rgb(0 0 0 / 15%);padding: 25px 0px 15px;}
#loader {background-color: #fbfbfb;background-position: center center;background-repeat: no-repeat;background-size: contain;bottom: 0px;left: 0px;position: fixed;right: 0px;top: 0px;background-size: 600px;z-index: 99999;}
.sap-mesg {position: relative;top: 60%;transform: translateY(-60%);color: #242424;font-size: 20px;}
.btn-loader {animation: fa-spin 1s infinite linear;display: flex;align-items: center;justify-content: center;margin: 5px 0 0 5px;position: static;left: 44px;width: auto;}
.Toastify__toast-container.Toastify__toast-container--top-right {height: auto !important;}
.discount-button { padding: 7px 19px 7px 19px; border: none; background: #27bdd8; color: #fff; border-radius: 5px; margin: 5px 0 5px 0; display: flex; align-items: center;}
.left {float: left;width: 65%;}
.discount-field {margin: 6px 0 0 0;padding: 6px 10px;border-radius: 5px;border: 1px solid #d2d2d2;width: 100%;color: #888 !important;}
.discount-field::placeholder{color: #888;}
input::placeholder {color: #27bdd8;}
.discount-field:focus {outline: 1px solid #4f92c7 !important;}
.coupon-code-error {color: red;font-weight: 500;}
.right.cut-ammount {text-decoration: line-through;}
.discount-field:focus-visible {border: 1px solid #27bdd8 !important;}
.discount-loader {position: static;top: 12px;left: 20px;margin: 6px 10px;}
.error-message {color: red;font-size: 14px;font-weight: 600;}
.user-field input {width: 100%;border-radius: 5px;border: 1px solid #d2d2d2;padding: 3px 5px;font-size: 14px;margin: 0px 0px 2px;}
.user-field input::placeholder {color: #beb9b9;}
.user-field button {background-color: #27bdd8 !important;border-color: #27bdd8 !important;padding: 5px 18px;border-radius: 5px;color: #fff;margin: 0px 2px;}
.speed-measurement {top: 31%;left: 50%;position: relative;transform: translate(-50%, -50%);font-weight: 600;color: #312424;}
.user-field input:focus-visible {outline: none;}
.user-field input:focus {border: 1px solid #242222;}
.user-field label {font-size: 14px;font-weight: 500;padding: 7px 0px 5px;}
.check-progressbar svg {width: 85px;height: 85px;top: 30%;left: 50%;position: absolute;transform: translate(-50%, -50%);}
.circle-progress {stroke: #046e04;stroke-dasharray: 76;stroke-dashoffset: 76;-webkit-animation: draw 2s forwards 1s;animation: draw 2s forwards 1s;animation-timing-function: linear;}
.tick-progress{
  stroke: #046e04;
  stroke-dasharray: 18;
  stroke-dashoffset: 18;
  -webkit-animation: draw 1s forwards 3s;
          animation: draw 1s forwards 3s;
          animation-timing-function: linear;
}
@-webkit-keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
@media (max-width: 767px) {
  .vcentre .uploadButtonMouseOver {
    top: 55%;
  }
  .sap-mesg {
    transform: translateY(0%);
    padding: 0 15px;
    top: 56%;
    display: inline-block;
  }
  .vcentre .uploadButtonMouseOver.position-fix:hover {
    bottom: 90px;
  }
  .vcentre .uploadButtonMouseOver.position-fix {
    bottom: 90px;
  }
}
@media (max-width: 499px) {
  .Canvas:before {left: 54%;transform: translate(-50%, -50%);height: 230px;width: 230px;top: 47%;}
  .frame.common-fram.Canvas {width: 100%;max-width: 215px;height: 215px;border: 0;}
  .frame.Canvas img {height: 224px !important;min-width: 228px !important;left: 2px;top: -10px;}
  #crop-modal {max-width: 334px;}
  .crop-pop-up .White,.crop-pop-up .Black,.crop-pop-up .White-Mount,.crop-pop-up .Black-Mount {height: 300px !important;}
  .crop-pop-up .crop-pop-img-head:not(.crop-pop-up .Canvas .crop-pop-img-head, .crop-pop-up .White-Mount .crop-pop-img-head, .crop-pop-up .Black-Mount .crop-pop-img-head) {height: 240px !important;}
  .crop-pop-up .crop-pop-img-head .cropper-container {height: 100% !important;width: 100% !important;}
  .crop-pop-up .White-Mount .crop-pop-img-head,.crop-pop-up .Black-Mount .crop-pop-img-head {height: 200px !important;width: 100% !important;}
  .crop-pop-up .White-Mount,.crop-pop-up .Black-Mount {padding: 20px;}
  .previewContainer .fram-main-box .Black-Mount,.previewContainer .fram-main-box .White-Mount {padding: 20px;}
  .crop-pop-up .Canvas .crop-pop-img-head {height: 240px !important;top: 0px;position: relative;width: 100% !important;left: 0px;}
  .crop-pop-up .Canvas:before {left: 50%;transform: translate(-50%, -50%);height: 240px;width: 240px;top: 50%;}
  #menuPopOver .dropdown {width: 10%;}
  .speed-measurement {top: 80%;left: 50%;position: relative;transform: translate(-50%, -50%);}
}
.wait-text {margin: 0 0 0 10px;color: #3e98c7;}
.wait-spinner {margin-top: 10px;color: #3e98c7;}
.slide-pane__content .btn-primary {width: 100%;background-color: #27bdd8 !important;border-color: #27bdd8 !important;min-height: 50px;display: flex;gap: 5px;align-items: center;justify-content: center;}
.progressbar {width: 85px;height: 85px;top: 25%;left: 50%;position: absolute;transform: translate(-50%, -50%);}
.upload-spinner {position: fixed;top: 133px;left: 0;width: 100%;height: 100%;color: #27bdd8;display: flex;align-items: center;justify-content: center;}
.spinner-border {margin-right: 5px;}
.App .uploadCircle {opacity: 0.7;position: relative;}
.scrollmenuPreview.select-photo {overflow: initial;}
.scrollmenuPreview.select-photo.home-loader .upload-spinner {color:#fff}
.btn-loader{-webkit-animation:fa-spin 0.5s infinite linear;animation:fa-spin 1s infinite linear;display:flex;align-items:center;justify-content:center;margin:0 0 0 5px}
.frame.common-fram.White-Mount, .frame.common-fram.Black-Mount, .frame.common-fram.White, .frame.common-fram.Black {filter: drop-shadow(3px 3px 7px rgb(0 0 0 / 15%));}
.frame.common-fram.White-Mount:before, .frame.common-fram.Black-Mount:before, .frame.common-fram.White:before, .frame.common-fram.Black:before {content: "";position: absolute;width: calc(100% - -3px);height: calc(100% - -2px);top: 0px;left: -3px;box-shadow: inset 0 0 7px 0 rgb(0 0 0 / 30%);}
@media (min-width: 500px) {
  .crop-pop-up .Canvas .crop-pop-img-head,
  .crop-pop-up .Canvas .cropper-container {height: 320px !important;top: -18px;position: relative;}
  .previewContainer .fram-main-box .frame {width: 380px !important;height: 380px;}
  .crop-pop-up .White .crop-pop-img-head .cropper-container, .crop-pop-up .Black .crop-pop-img-head .cropper-container{height: 320px !important;width: 100% !important;}
.White .crop-pop-img-head, .Black .crop-pop-img-head {height: 100% !important;}
}
.frame.Canvas .canvas-edge:before, .frame.Canvas .canvas-edge:after {content: "";border: 10px solid #e7e7e7;display: block;position: absolute;z-index: 1;}
.frame.Canvas .canvas-edge:before {border-bottom: 10px solid transparent;border-right: 10px solid transparent;top: -21px;left: 0px;}
.frame.Canvas .canvas-edge:after {border-top: 10px solid transparent;border-left: 10px solid transparent;bottom: 0;right: -21px;}
.previewContainer .fram-main-box {padding: 0;margin: 0 auto;position: relative;}
.frame.Canvas .canvas-edge {position: absolute;top: 0;left: 0;width: 100%;height: 100%;z-index: 11;display: none;}
.canvas-burn-border{position:absolute;top:0;left:0;width:100%;height:100%;z-index:15}
.canvas-burn-border:before{content:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='338' height='19.008' viewBox='0 0 338 19.008'%3E%3Cpath id='Path_1' data-name='Path 1' d='M301.213,18.989-18,18.576,1.051,0,320-.018Z' transform='translate(18 0.018)'/%3E%3C/svg%3E%0A");top:-20px;left:1px;position:absolute;width:320px;height:auto;opacity:.4}
.canvas-burn-border:after{content:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='340' viewBox='0 0 20 340'%3E%3Cpath id='Path_2' data-name='Path 2' d='M321.1,0,0,.435,19.164,19.981,340,20Z' transform='translate(20) rotate(90)'/%3E%3C/svg%3E%0A");top:-20px;right:-20px;position:absolute;width:auto;height:340px;opacity:.4}
.frame.common-fram.Black-Mount, .frame.common-fram.Black {filter: drop-shadow(3px 3px 7px rgba(0, 0, 0, 0.50));}
.Toastify__toast-theme--colored.Toastify__toast--success {background-color: #27bdd8 !important;}
@media(max-width:499px){
.frame.Canvas .canvas-edge:before {top: -1px;left: 0px;}
.frame.Canvas .canvas-edge:after {bottom: 0px;right: -16px;}
.canvas-burn-border:before{content:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='229' height='16' viewBox='0 0 229 16'%3E%3Cpath id='Path_1' data-name='Path 1' d='M189.951,16.994-21,16.914-5.555.994,208,1.117Z' transform='translate(21 -0.994)'/%3E%3C/svg%3E%0A");top:-2px;left:2px;position:absolute;width:227px;height:auto;opacity:.4}
.canvas-burn-border:after{content:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='211' viewBox='0 0 17 211'%3E%3Cpath id='Path_3' data-name='Path 3' d='M195.627.656-.863.375l14.982,17,196.018-.28Z' transform='translate(17.375 0.863) rotate(90)'/%3E%3C/svg%3E%0A");top:1px;right:-15px;position:absolute;width:auto;height:227px;opacity:.4}
.scrollmenuPreview.select-photo.home-loader .upload-spinner { top: 200px;}
}
.scrollmenuPreview.select-photo.home-loader .upload-spinner {position: fixed;top: 0;left: 0;width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;background-color: rgb(0 0 0 / 60%);color: #fff;transform: none;opacity:1;transition: all .3s ease-in-out;z-index: 9999;}
.discount-icon{cursor: pointer;}
.discount-icon span svg {border: 2px solid #27bdd8;padding: 2px;border-radius: 50px;width: 18px;height: 18px;}
.discount-icon span svg path {fill: #27bdd8;}
.discount-icon span {position: relative;top: -1px;}
.frame.common-fram.White{border-image-slice: 27 26 23 21;}
.frame.common-fram.Black {border-image-slice: 27 32 23 21;}
.frame.common-fram.White-Mount{border-image-slice: 26 25 23 26;}
.frame.common-fram.Black-Mount {border-image-slice: 27 32 23 21;}
@media(min-width:350px) and (max-width:499px){
.frame {height: 300px;width: 300px;}
.frame.common-fram.Canvas {max-width: 300px;height: 300px;}
.canvas-burn-border:before {content: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='302' height='23' viewBox='0 0 229 16'%3E%3Cpath id='Path_1' data-name='Path 1' d='M189.951,16.994-21,16.914-5.555.994,208,1.117Z' transform='translate(21 -0.994)'/%3E%3C/svg%3E%0A");top: -1px;left: -1px;}
.canvas-burn-border:after{content: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='293' viewBox='0 0 17 211'%3E%3Cpath id='Path_3' data-name='Path 3' d='M195.627.656-.863.375l14.982,17,196.018-.28Z' transform='translate(17.375 0.863) rotate(90)'/%3E%3C/svg%3E%0A");top: -5px;right: -2px;}
.frame.Canvas img {height: 291px !important;}
.frame.Canvas .canvas-edge:after {bottom: 19px;right: -2px;}
}
header{min-height:72px;border-bottom:1px solid rgb(235,231,231);display:flex;justify-content:center;align-items:center;position:sticky;top:0;left:0;width:100%;background-color:#fff;z-index:999}
header .logo img{height:40px}
.homepage-con section{padding:35px 0}
.thi-left{height:500px;min-width:500px;max-width:500px;overflow:hidden;border-radius:10px}
#thi-right1{height:200px;min-width:200px;max-width:200px;overflow:hidden;border-radius:6px}
#thi-right2{height:285px;min-width:285px;max-width:285px;overflow:hidden;border-radius:6px}
.thi-left img,.thi-right img{height:100%;width:100%;object-fit:cover}
.tri-hero-img{display:flex;align-items:center;grid-gap:20px}
.thi-right-head{grid-gap:20px;display:grid}
.hero-sec-inner{display:flex;align-items:center;justify-content:space-around}
body{font-family:'Open Sans',sans-serif}
.homepage-con{background-color:#fbf9f9}
.heading-70{font-size:60px;font-weight:650;line-height:76px;letter-spacing:-5.5px;padding:0 0 30px}
.hero-sec-inner .right-sec .text-para{font-size:20px;padding:0 0 30px;width:100%;max-width:400px}
.sap-btn-dark button,.sap-btn-dark a,.sap-btn-light button,.sap-btn-light a{min-height:60px;min-width:160px;text-decoration:none;outline:0;border:2px solid transparent;border-radius:10px;font-size:20px;font-weight:600;background-color:transparent;color:#fff;transition:all .3s ease-in-out;padding:8px 30px;display:inline-flex;align-items:center;justify-content:center}
.hero-sec-inner .right-sec .sap-btn-dark button{min-width:300px}
.sap-btn-dark button:hover,.sap-btn-dark a:hover{background-color:#23a5bd;border-color:#23a5bd}
.shipping-subtitle{font-size:20px;font-weight:600;padding:30px 0 0}
.hero-sec-inner .right-sec{width:100%;max-width:580px;padding:0 0 0 30px}
.shipping-subtitle i{color:#27bdd8;padding:0 6px 0 0;position:relative;top:1px}
.tri-icon-list{display:flex;justify-content:space-between;grid-gap:50px;margin:0;padding:0;list-style-type:none}
.til-ico svg{height:105px;width:105px;}
.tri-icon-list li{display:flex;flex-direction:column;align-items:center;text-align:center}
.til-title{font-size:18px;font-weight:600;padding:0 0 5px}
.til-subtitle{font-size:16px}
.til-ico{margin:0 0 10px}
.tri-icon-sec-inner{border-top:1px solid rgb(235,231,231);padding-top:50px}
.homepage-con .hero-sec{padding-bottom:20px}
.grey-back{background-color:rgb(39 189 216 / 15%)}
.sapatron-sec-inner{border-radius:10px;padding:35px !important;display:flex;justify-content:space-between;align-items:center}
.heading-44{font-size:44px;font-weight:700;line-height:50px;letter-spacing:-2.5px;padding:0 0 18px}
.sapatron-sec-inner .left-sec .text-para{font-weight:600;font-size:18px}
.sapatron-sec-inner .left-sec{width:100%;max-width:440px;position:relative;left:8%}
.tron-img video,.tron-img img{height:420px;width:100%;max-width:420px;object-fit:cover;border-radius:10px;overflow:hidden}
.homepage-con .tri-icon-sec{padding-bottom:20px}
.heading-30{font-size:30px;font-weight:700;line-height:36px;letter-spacing:-1.5px;padding:0 0 12px}
.review-sec-inner .text-para.text-center{font-weight:600;font-size:18px;padding:0 0 40px}
.rev-thumb{box-shadow:0 4px 4px rgb(0 0 0 / 2%);border-radius:10px;border:1px solid #EDEDED;overflow:hidden;}
.rst-img img{width:100%;height:100%;object-fit:cover}
.review-sec .slick-slide div:not(.review-sec .slick-slide div *) {margin: 0 10px;}
.rst-img{height:335px;width:100%}
.rst-un{padding:0 15px 18px;font-size:14px;font-weight:400;letter-spacing:normal;margin-top:0;color:rgb(128,128,128)}
.rst-con{padding:18px 15px 0;min-height:80px;font-size:15px;line-height:18px}
.sp-vid-head{position:relative}
.sp-vid-thumb{height:470px;overflow:hidden;border-radius:10px}
.sp-vid-con{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);display:flex;flex-direction:column;align-items:center;justify-content:center;text-align:center;width:100%;max-width:460px;z-index:1}
.heading-34{font-size:34px;font-weight:700;line-height:40px;letter-spacing:-1.5px;padding:0 0 18px;color:#fff}
.sap-btn-dark button,.sap-btn-dark a{border-color:#27bdd8;background-color:#27bdd8}
.sap-btn-light button,.sap-btn-light a{background-color:#fff;color:#27bdd8}
.sap-btn-dark button i,.sap-btn-dark a i,.sap-btn-light button i,.sap-btn-light a i{font-size:18px;padding:0 8px 0 0}
.btn-sm button,.btn-sm a{min-height:47px;font-size:14px}
.btn-sm button i,.btn-sm a i{font-size:12px}
.sp-vid-head:hover .sp-vid-thumb img{transform:scale(1.15)}
.sp-vid-head .sp-vid-thumb img{width: 100%; height: 100%; object-fit: cover; transition:all 1.2s ease-in-out}
.its-list{list-style-type:none;padding:0;margin:0;display:flex;justify-content:space-around}
.its-list li{display:flex;flex-direction:column;align-items:center;text-align:center}
.its-logo{display:flex;align-items:flex-start;grid-gap:30px}
.its-list li .text-para{font-size:18px;font-weight:600;padding:10px 0 5px;line-height:normal}
.its-subtitle{font-size:16px}
.its-img img{height:23px}
footer{padding:100px 0 0;border-top:1px solid rgb(235,231,231)}
.foot-heading{font-size:16px;font-weight:600;padding:0 0 20px}
.foot-con-heading{display:flex}
.foot-con{width:100%;flex-basis:100%;max-width:50%}
footer ul{padding:0;margin:0;list-style-type:none}
.foot-con ul li a{font-size:16px;color:#000;text-decoration:none;padding:0 0 20px;display:inline-block;cursor: pointer;}
.foot-con ul li:last-child a{padding-bottom:0}
.foot-copy{display:flex;margin:70px 0 40px;padding:40px 0 0;justify-content:space-between;font-size:12px;border-top:1px solid rgb(235,231,231)}
.foot-copy ul{display:flex;grid-gap:20px}
.foot-copy ul li a{color:#000;font-size:12px}
footer a:hover{color:#27bdd8!important}
footer a{transition:all .3s ease-in-out}
#about_us_video iframe{width:100%;height:350px;border-radius:10px}
.review-sec .slick-slider{position:relative}
.review-sec .slick-prev,.review-sec .slick-next{height:77px;width:77px;background:#ffffff!important;box-shadow:0 4px 13px #2b05140a;border-radius:50px;border:unset;display: flex !important;justify-content:center;align-items:center;z-index:2;font-size:0;}
.review-sec .slick-prev{top:50%;left:80px;transform:translateX(-50%)}
.review-sec .slick-next{top:50%;right:0;transform:translateX(-50%)}
.review-sec .slick-prev:before{content:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.323' height='20' viewBox='0 0 23.323 20'%3E%3Cpath id='Path_1' data-name='Path 1' d='M.513,72.748a1.669,1.669,0,0,0,0,2.358l8.33,8.33A1.668,1.668,0,0,0,11.2,81.078L5.709,75.591H21.682a1.666,1.666,0,1,0,0-3.332H5.714L11.2,66.772a1.668,1.668,0,0,0-2.358-2.358l-8.33,8.33Z' transform='translate(-0.025 -63.925)' fill='%2327bdd8'/%3E%3C/svg%3E%0A");position: relative;top: 2px;}
.review-sec .slick-next:before{content:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.323' height='20' viewBox='0 0 23.323 20'%3E%3Cpath id='Path_2' data-name='Path 2' d='M22.86,72.748a1.669,1.669,0,0,1,0,2.358l-8.33,8.33a1.668,1.668,0,1,1-2.358-2.358l5.493-5.487H1.691a1.666,1.666,0,1,1,0-3.332H17.659l-5.482-5.487a1.668,1.668,0,1,1,2.358-2.358l8.33,8.33Z' transform='translate(-0.025 -63.925)' fill='%2327bdd8'/%3E%3C/svg%3E%0A");position: relative;top: 2px;}
.review-sec .slick-dots{display:none!important}
.review-sec .slick-list:before{content:"";width:250px;height:100%;display:block;position:absolute;left:-1px;top:0;z-index:1;background:linear-gradient(90deg,rgb(251,249,249) 25%,rgba(251,249,249,0) 100%)}
.review-sec .slick-list:after{content:"";width:250px;height:100%;display:block;position:absolute;right:-1px;top:0;z-index:1;background:linear-gradient(-90deg,rgb(251,249,249) 25%,rgba(251,249,249,0) 100%)}


.faq-cont-head {color: #27bdd8;font-size: 17px;font-weight: 500;}
.faq-para {font-size: 14px;}
#FAQ .modal-body{height: 500px;overflow-y: scroll;}
.checkout-spinner {position: fixed;top: 0;left: 0;width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;background-color: rgb(0 0 0 / 60%);color: #fff;transform: none;opacity:1;transition: all .3s ease-in-out;z-index: 9999;}
#desktop-right-head .cls-1,#desktop-top-head .cls-1,#mob-hor-top-head .cls-1,#mob-hor-right-head .cls-1,#mob-vert-top-head .cls-1,#mob-vert-right-head .cls-1 {clip-path: url(#clip-path);}#desktop-right-head .cls-2,#desktop-top-head .cls-2,#mob-hor-top-head .cls-2,#mob-hor-right-head .cls-2,#mob-vert-top-head .cls-2,#mob-vert-right-head .cls-2{fill: url(#pattern);}
.ref-head-mob-vert,.ref-head-mob-hor{display:none}
.reflection-head{position:absolute;top:0;left:0;height:100%;width:100%;z-index:2}
#desktop-top-head{position:absolute;top: -43px;left: 15px;z-index:3;height:19px;width: 106.5%;transform:skewX(-46deg)}
#desktop-right-head{position:absolute;top: -29px;right: -40px;z-index:3;height: calc(100% + 20px);width:20px;transform: skewY(-43deg);}
.frame.Canvas .reflection-head img{height:100%!important;width:100%!important;object-fit:cover!important;position:relative!important;left:auto!important;top:auto!important;min-width:auto!important}
#desktop-top-head img{object-position: 0% 0%;transform:rotateX(180deg);filter:brightness(0.9)}
#desktop-right-head img{object-position:100% 0;transform:rotateY(180deg);filter:brightness(0.9)}
.canvas-head .Canvas:first-child {margin-left: 0 !important;}
.canvas-head {padding: 0 10px !important;}

.stipics-list1 {padding: 20px;}
.stipics-list2 {padding: 15px;}
.center-button {text-align: center;}

.slide-pane_from_right .drawer-loader {display: flex;align-items: center;justify-content: center;height: calc(100% - 70px);position: absolute;top: 0;left: 0;width: 100%;background-color: rgb(255 255 255 / 85%);z-index: 9;}
.slide-pane_from_right .drawer-loader .spinner-border {border-color: #27bdd8;border-right-color: transparent;}

.hero-sec-inner .slick-slider{width:100%;max-width:570px}
.tri-hero-img{display:flex!important;justify-content:center}
.slick-prev::before{content:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='25' width='25' viewBox='0 0 256 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath fill='%2323a5bd' d='M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z'/%3E%3C/svg%3E")}
.slick-next::before{content:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='25' width='25' viewBox='0 0 256 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath fill='%2323a5bd' d='M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z'/%3E%3C/svg%3E")}
.til-head-sap{display:flex!important;flex-direction:column;align-items:center;text-align:center}
.mobile-only{display:none}
.til-ico img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.til-ico {
  height: 105px;
  width: 105px;
}

.stipics-list1{padding:0}
.home-tri-sec .sapatron-sec-inner{display:grid;grid-template-columns:repeat(3,1fr);gap:20px;align-items:start}
.home-tri-sec .sapatron-sec-inner .stipics-list1 img{width:100%;object-fit:cover;height:350px;border-radius: 10px;}
.home-tri-sec .sapatron-sec-inner .stipics-list1 h3{padding:12px 0 0}



























@media(max-width:1199px){
#thi-right2{height:195px;max-width:195px;min-width:195px}
#thi-right1{height:150px;max-width:150px;min-width:150px}
.thi-left{height:300px;max-width:300px;min-width:300px}
.heading-70{font-size:40px;letter-spacing:-1px;line-height:46px;padding:0 0 15px}
.heading-44{font-size:27px;letter-spacing:-1px;line-height:33px;padding:0 0 8px}
.its-list li:not(.its-list li:last-child){padding:0 0 25px}
.tron-img img,.tron-img video{height:350px;max-width:350px}
.review-sec .slick-list:after,.review-sec .slick-list:before{width:60px}
.review-sec .slick-next,.review-sec .slick-prev{height:45px;width:45px}
.review-sec .slick-prev{left:40px}
}


@media(max-width:991px){
.sapatron-sec-inner .left-sec{left:0;padding: 0px 15px 0px 0px;max-width: 300px;}
.its-list{flex-direction:column;} 
.mobile-only{display:block;width:100%}
.desktop-only{display:none}
}


@media(max-width:767px){
.hero-sec-inner{flex-direction:column;max-width:410px}
.hero-sec-inner .right-sec{max-width:100%;padding:30px 0 0}
.tri-icon-list{flex-direction:column;}
.sapatron-sec-inner{flex-direction:column-reverse;}
.sapatron-sec-inner .left-sec{left:0;padding:30px 0 0}
#thi-right2{height:155px;max-width:155px;min-width:155px}
#thi-right1{height:110px;max-width:110px;min-width:110px}
.thi-left{height:300px;max-width:300px;min-width:300px}
.hero-sec-inner{flex-direction:column-reverse}
.hero-sec-inner .right-sec{padding:0 0 30px}
.hero-sec-inner .right-sec .sap-btn-dark{position:fixed;bottom:0;background-color:white;width:100%;left:0;display:flex;justify-content:center;align-items:center;padding:10px;z-index:9;border-top:1px solid #ebe7e7}
.hero-sec-inner .right-sec .sap-btn-dark button,.hero-sec-inner .right-sec .sap-btn-dark a{min-height:50px;font-size:18px;width:100%}

header{min-height:55px}
.hero-sec-inner{flex-direction:column}
.hero-sec-inner .right-sec{padding:30px 0 0}
.hero-sec-inner .slick-slider{max-width:300px}
.tri-icon-list-slider{max-width:250px;margin:0 auto}
.home-tri-sec .sapatron-sec-inner{grid-template-columns:repeat(1,1fr);row-gap:40px}
.home-tri-sec .sapatron-sec-inner .stipics-list1 img{height:300px;}

}





@media(max-width:499px){
#desktop-top-head {height: 19px;left: 15px;top: -33px;width: 100.7%;}
#desktop-right-head {height: calc(100% + -9px);right: -20px;top: -19px;width: 20px;}
.frame.common-fram.Canvas.black-frame {margin: 30px 25px 30px;}

}

@media(max-width:349px){
#desktop-top-head {height: 16px;left: 16px;top: -32px;width: 106.6%;}
#desktop-right-head {height: calc(100% + 10px);right: -32px;top: -18px;width: 17px;}

}

@media(min-width:500px) and (max-width:767px) {
.tri-icon-list-slider {max-width: 450px !important;}
.home-tri-sec .sapatron-sec-inner .stipics-list1 img{height:500px}

}

@media(min-width:768px) and (max-width:991px) {
.home-tri-sec .sapatron-sec-inner .stipics-list1 img{height:220px}

}

@media(min-width:768px) and (max-width:1199px) {
.hero-sec-inner .slick-slider{max-width:345px}
.hero-sec-inner .right-sec{max-width:440px}

}